$hr-height: 1px;
@import "~bulma";

.category {
  background-color: $success-light;
  border-bottom: 1px solid $grey-lighter;
  padding: 2rem 0;

  &:nth-child(even) {
    background-color: $info-light;
  }
}

.is-transparent {
  background: transparent !important;
}
