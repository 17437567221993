.home {
  &__item {
    position: relative;
  }

  &__item-form {
    max-width: 800px;
  }

  &__item-delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  &__actions {
    margin-top: 2rem;

    > .buttons {
      margin: 0 auto;
      max-width: 470px;
    }
  }
}
