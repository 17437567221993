@keyframes expand {
  40% {
    opacity: 0;
  }
  99% {
    overflow: hidden;
  }
  100% {
    opacity: 1;
    max-height: 45rem;
    overflow: visible;
  }
}

.calculate {
  padding: 2rem 0;

  &__working {
    animation: expand 400ms ease-out 0ms 1 normal forwards;
    opacity: 0;
    margin: 2rem auto 0;
    max-height: 0;
    max-width: 470px;
  }
}
