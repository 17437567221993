.app {
  &__footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  .calculate + &__footer a {
    color: white !important;
  }
}
